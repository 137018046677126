
// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
    .dash-content-wrap{
      h5{
        font-size:14px;
      }
      h6{
        font-size:14px;
      }
      .MuiCardContent-root .MuiTypography-root{
        font-size:12px;
      }
    }
 }



















