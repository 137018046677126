// ForgotPwd.scss

.capture-block {
    display: flex;
    align-items: center;
    gap: 50px;
  
    .capture-img {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
      background-image: url(https://www.optacom.com/components/com_breezingforms/images/captcha/backgrounds/bg6.png);
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 65px;
      min-width: 221px;
      border: 1px solid #ccc;
      max-width: 200px;
    }
  
    .create-ac-btn {
      min-width: 217px;
      height: 62px;
      background-color: #000;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
      max-width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 10px;
      cursor: pointer;
    }
  }

  @media(min-width:320px) and (max-width:600px) {
    .capture-block {
        gap: 15px;
    }

    .capture-block .capture-img {
        min-width: 120px;
    }

    .capture-block .create-ac-btn {
        min-width: 120px;
        font-size: 14px;
    }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .forgot-block .company-upper .MuiGrid-item {
        max-width: 100%;
    }
}
  