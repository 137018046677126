@import '../../../../assets/CSS/colors.scss';

.return-items {
    padding: 16px;

    .heading-sec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 20px;

        .btn-sec {
            button {
                background-color: $theme_primary_clr;
                color: $white;
                border: none;
                padding: 12px 20px;
                font-size: 14px;
                font-weight: 600;

                .MuiTouchRipple-root {
                    display: none;
                }
            }
        }
    }

    .title {
        font-weight: 700;
        margin-bottom: 8px;
        font-size: 18px;
        color: $gray_clr;
        font-family: Poppins;
    }

    .subtitle {
        font-weight: 400;
        margin-bottom: 16px;
        font-size: 13px;
        color: $black;
        font-family: Poppins;
    }

    .desktop-view {
        display: table;
        width: 100%;

        .header-cell {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: left;

        }

        .body-cell {
            font-family: Poppins;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
        }

        .product-image {
            width: 50px;
            height: 50px;
            object-fit: cover;
        }

        .no-image {
            font-size: 0.85rem;
            color: $gray_clr_othr;
        }

        .status {
            &.pending {
                color: $prnding_clr;
            }

            &.completed {
                color: $completed_clr;
            }
        }

        table {
            thead {
                tr {
                    th {
                        &.header-cell {
                            border: 1px solid $border_clr;
                        }

                        &:first-child {
                            border-left: none;
                        }

                        &:last-child {
                            border-right: none;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        &.body-cell {
                            border: 1px solid $border_clr;
                        }

                        &:first-child {
                            border-left: none;
                        }

                        &:last-child {
                            border-right: none;
                        }
                    }
                }
            }
        }
    }

    .mobile-view {
        display: none;
    }

    .table-container {
        margin-bottom: 16px;
        border: 1px solid $light_gray;
        border-radius: 8px;
        overflow: hidden;

        .MuiTable-root {
            width: 100%;
        }

        .MuiTableCell-root {
            padding: 10px;
            border-right: 1px solid $light_gray;
            font-family: Poppins;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;


            &.header {
                font-weight: bold;
                color: $gray_clr;
                width: 40%;
            }

        }
    }



    @media (max-width: 650px) {

        .return-items {
            .title {
                font-size: 14px;
            }

            .subtitle {
                font-size: 10px;
            }

            .heading-sec {
                .btn-sec {
                    button {
                        padding: 8px 8px;
                        font-size: 12px;
                    }
                }
            }
        }

        .desktop-view {
            display: none;
        }

        .mobile-view {
            display: block;

            .table-container {
                margin-bottom: 1.5rem;
                border-radius: 0px;

                table {
                    width: 100%;
                    background-color: $white;


                    tbody {
                        tr {
                            align-items: center;
                            border-bottom: 1px solid $light_gray;

                            &:last-child {
                                border-bottom: none;
                            }

                            td {

                                &.header {
                                    font-family: Poppins;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 21px;
                                    text-align: start;
                                    border-right: 1px solid $light_gray;
                                }

                                .product-image {
                                    width: 50px;
                                    height: 50px;
                                    object-fit: cover;
                                    border-radius: 4px;
                                }

                                .no-image {
                                    font-size: 0.85rem;
                                    color: $gray_clr_othr;
                                }

                                .status {
                                    &.pending {
                                        color: $prnding_clr;
                                    }

                                    &.completed {
                                        color: $completed_clr;
                                    }

                                    &.cancelled {
                                        color: $cancel_clr;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (max-width:575px) {
    .return-items {
        .heading-sec {
            flex-wrap: wrap;
            margin-bottom: 20px;
            gap: 0px;

            .name-sec {
                h2 {
                    font-size: 14px;
                    margin-bottom: 4px;
                }

                p {
                    font-size: 11px;
                }
            }

            .btn-sec {
                button {
                    padding: 8px 12px;
                    font-size: 12px;
                }
            }
        }
    }
}