@import './colors.scss';

.fill-primary-btn {
    background: $theme_primary_clr;
    color: $white;
    padding: 12px 24px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;

    .MuiTouchRipple-root {
        display: none;
    }
}