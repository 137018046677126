.category-wrap {
    margin-top: 0 !important;
    display: flex;
    justify-content: center;
    li:not(:last-child) {
        margin-right: 20px;
        display: flex;
    }
}
.category-home-grid{
    .MuiCardContent-root{
        background-color:#fafafa;
    }
}
.carousel .control-dots .dot,
.carousel .control-dots .dot:hover {
    &.selected {
        background-color: #000000;
    }
}
.shop-now-box {
    margin: 0px 15px;
}
.top-slider{
	height: 100%;
    // border: 1px solid red;
    overflow: hidden;
	// background-color: #c9c9c9;
    img{
        max-width: 100%;
        height: 100%;
        width: 100%;
    }
	.react-multi-carousel-list {
		height:100%;
		align-items: inherit;
}
.react-multiple-carousel__arrow{
        position: absolute;               
		top: 50%;                         
		transform: translate(0, -50%);
        z-index:2 !important;
}
.image-item {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
.jeb-cust-slide {
    .react-multiple-carousel__arrow:hover,
    .react-multiple-carousel__arrow {
        background: transparent !important;
    }
    .react-multiple-carousel__arrow--left {
        left: calc(0% + -17px) !important;
    }
    .react-multiple-carousel__arrow::before {
        color: #000;
        font-weight: 700;
    }
    .react-multiple-carousel__arrow--right {
        right: calc(0% + -17px);
    }
}
@media (max-width: 767px) {
    .home-wrapper{
        .css-z39whn {
            padding-top: 30px;
        }
        .css-1a8ogn0-MuiCardContent-root {
            padding: 10px;
        }
        .shop-now-box {
            margin: 0px 3px;
        }
        .shop-now-grid{
            >.css-46bh2p-MuiCardContent-root{
                padding-top: 0;
            }
        }
    }
    .css-idvdqs-MuiCardContent-root:last-child {
        padding-bottom:0px !important
    }
    .category-home-grid {
        .MuiCardContent-root {
            padding-bottom: 0 !important;
        }
    }
    .top-slider {
        .image-item {
            background-size: cover;
        }
        .react-multiple-carousel__arrow {
            display: none;
        }
        .react-multi-carousel-dot {
            button {
                width: 21px;
                height: 4px;
                background: #D1D2D2;
                border: unset;
            }
        }
        .react-multi-carousel-dot--active{
            button {
                background: #00B4D7 !important;
            }
        }
    }
    .jeb-cust-slide {
       .react-multiple-carousel__arrow {
            background: #fff !important;
            box-shadow: 0px 1px 5px #0000004a;
            border-radius: unset;
            min-width: 29px;
            min-height: 22px;
            font-size: 11px;
            text-align: center;
            top: 44%;
            transform: translateY(-50%);
            &::before {
                font-size: 14px;
            }
        }
        .react-multiple-carousel__arrow--left {
            left: calc(0% - -6px) !important;
        }
        .react-multiple-carousel__arrow--right {
            right: calc(0% - -6px);
        }
    }
    .brewers-grid{
        .css-5gesyn-MuiImageList-root {
            gap: 8px !important;
        }
    }
    .brand-grid{
        .css-k1h1aa-MuiGrid2-root>div {
            min-height: 72px;
        }
    }
}
