.container {
    max-width: 1220px;
    width: 100%;
    margin: auto;
    padding: 50px 0px 101px;
}
ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
// .PaymentWrapper {
//     padding: 83px 0px 101px;
//     .web-policy {
       
//         h2, .titlehead {
//             color: #050505;
//             margin-bottom: 25px;
//             font-size: 35px;
//             text-align: left;
//             margin-top: 0;
//             font-weight: 700;
//             text-transform: capitalize;
//         }
//         h3 {
//             color: #050505;
//             margin: 20px 0 7px;
//             font-size: 18px;
//             text-align: left;
//             font-weight: 700;
//         }
//         p {
//             font-weight: 400;
//             text-align: left;
//             margin-bottom: 25px;
//             color: #000;
//             font-size: 18px;
//             margin-top: 0;
//         }
//         h2.legend {
//             color: #000;
//             font-family: Poppins;
//             font-size: 22px;
//             font-style: normal;
//             font-weight: 500;
//             line-height: normal;
//         }
//         &.contactpage{
//             .row{
//                 display: flex;
//                 justify-content: space-between;
//                 .detail_sec, ul{
//                     padding: 0;
//                     margin: 0;
//                     list-style: none;
//                     li{
//                         p{
//                             color: #000;
//                             font-size: 18px;
//                             font-style: normal;
//                             font-weight: 400;
//                             line-height: normal;
//                             margin-bottom: 15px;
//                         }
//                         &.address_icon{
//                             p{
//                                 margin-bottom: 0;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
   
// }


@media screen and (min-width:1024px) and (max-width:1366px){
    // .PaymentWrapper {
    //     .web-policy {
    //         .container {
    //             padding: 0px 50px;
    //         }
    //     }
    // }
    .container {
        padding: 50px;
    }
}

@media screen and (min-width:768px) and (max-width:1023px){
    // .PaymentWrapper {
    //     padding: 50px 0px 60px;
    //     .web-policy {
    //         .container {
    //             padding: 0px 50px;
    //         }
    //         h2, .titlehead  {
    //             margin-bottom: 15px;
    //             font-size: 30px;
    //         }
    //         p {
    //             font-size: 15px;
    //             line-height: 25px;
    //         }
    //         &.contactpage{
    //             .row {
    //                 flex-wrap: wrap;
    //                 gap: 40px;
    //             }
    //         }
    //     }
    // }
    .container {
        padding: 50px;
    }
}

@media screen and (max-width:767px){
    // .PaymentWrapper {
    //     padding: 30px 0px 50px;
    //     .web-policy {
    //         .container {
    //             padding: 0px 20px;
    //         }
    //         h2, .titlehead  {
    //             margin-bottom: 10px;
    //             font-size: 27px;
    //         }
    //         p {
    //             font-size: 13px;
    //             line-height: 23px;
    //         }
    //         &.contactpage{
    //             .row {
    //                 flex-wrap: wrap;
    //                 gap: 40px;
    //                 .detail_sec, ul{
    //                     li {
    //                         p {
    //                             font-size: 14px;
    //                         }

    //                     }
    //                 }
    //             }
    //             h2.legend {
    //                 font-size: 19px;
    //                 margin-top: 19px;
    //             }
    //         }
    //     }
    // }
    .container {
        padding: 20px;
    }
}