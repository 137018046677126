.overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 106vh !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 9999;
    cursor: pointer;
    user-select: none;
}
.custom_loader{
    left: 50%;
    position: absolute;
    top: 50%;
    width: 25%;
    transform: translate(-50%, -50%);
}

@media screen and (min-width:768px) and (max-width:1023px){
    .custom_loader {
        width: 50%;
    }
}

@media screen and (max-width:767px){
    .custom_loader {
        width: 80%;
    }
}