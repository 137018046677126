.profile-box {
    .add-new-address {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        button {
            background-color: #00B4D7;
            color: #fff;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            padding: 14px 22px;
            border: none;
            max-height: 50px;
            width: max-content;
            cursor: pointer;
        }
    }
    
      
    .general-info-box {
        .input-grp {
            .input-box {
                &.adddress-input {
                    flex: 2 2 2%;
                }
            }
        }
    }
}
.MuiFormHelperText-root {
    color: red;
    font-size: 0.75rem;
  }
@media (max-width:575px) {
    .profile-box {
        &.profile-address {
            .add-new-address {
                h4 {
                    font-size: 14px;
                    line-height: 20px;
                }

                p {
                    font-size: 10px;
                    line-height: 14px;
                }

                button {
                    padding: 5px 8px;
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            .general-info-box {
                .input-grp {
                    flex-direction: column;
                    margin-bottom: 14px;
                }
            }
        }
    }
}

