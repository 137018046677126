.ui.selection.dropdown,
.ui.selection.dropdown .menu {
  background: #050505 !important;
  color: #ffffff !important;
}

.ui.dropdown .menu>.item,
.ui.selection.visible.dropdown>.text:not(.default) {
  color: #ffffff !important;
}

//New header css
.flag-img{
  height:23px;
}

.header-content {
  background: #050505;
  box-shadow: 0 2px 5px rgb(0 0 0 / 5%);
  width: 100%;
}
.page-header{
.MuiAppBar-root {
  background-color: #050505 !important;
}
}

.logo-container{
  min-height:45px;
  min-width: 130px;
}

.logo-nav {
  display: flex;
  align-items: center;
}
.search-nav{
  input{
    padding-left: 20px !important;
  }
  .search-icon{
    right: 0;
  }
}

header{
.store-selection-drop {
  span {
      opacity: 1;
      -webkit-text-fill-color: rgb(255 255 255 / 100%) !important;
  }
}
}

.language-box {
  button {
    color: #ffffff !important;
  }
}

.right-icon-box {
  .MuiDivider-root {
    border-color: #f1f1f1;
  }

  .icon-btn-wrap {
    position: relative;
    display: flex;
    align-items: center;

    a {
      color: #ffffff;
      font-size: 16px;
      align-items: center;
      display: flex;
    }
    

    .MuiSelect-select {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}

.header{
.MuiOutlinedInput-notchedOutline {
  border-color: unset !important;
  border-width: 0px !important;
}
}

.flag-wrap {
  .MuiInputBase-root,
  .MuiSvgIcon-root {
    color: #ffffff;
  }
  .lang-control{
    [role="combobox"]{
      padding: 0px !important;
    }
  }
  .MuiSvgIcon-root {
    display:none;
  }
}

.sub-header-wrap {
  min-height: 44px;
  padding: 10px 7px;

  .category-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 40px;
    flex-wrap: wrap;
  }
  .activeCategory{
    .sub-head-btn{
      color: #06b4d8;
    }
  }
}
.inner-menu-anchor{
  color:#000;
}
.activeSubCategory{
  color:#06b4d8;
}

.MuiTooltip-popper {
  width: 100%;
}

.sub-head-btn {
  display: flex;
  align-items: center;
  color: #fff;
  white-space: nowrap;
  font-size: 12px;
  letter-spacing: 1px;

  span {
    order: 1;
    display: flex;
    line-height: 1.15 !important;
  }

  &:hover,
  &.active-menu {
    color: #06b4d8;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.inner-menu-list {
  width: 235px;

  .inner-menus {
    a {
      font-size: 14px;
      font-weight: 600;
      color: rgb(0, 0, 0);
    }
  }
}

.sb-title {
  font-weight: 600;
  color: #000;
  margin-bottom: 5px;
}

//Direction rts
body.ar {
  .page-header {
    direction: rtl;
  }
  .search-nav {
    .css-1xedmm4 {
      margin-right: 72px;
      margin-left: unset;
    }

    .css-aeiljy {
      left: 0;
      right: unset;
    }
  }
  .right-icon-box {
    .MuiStack-root>:not(style)~:not(style) {
      margin-right: 16px;
      margin-left: unset;
  }
  .icon-btn-wrap{
    a .MuiBox-root{
      margin-left: 5px;
      margin-right:unset;
    }
  }
  }

  .category-wrap li{
    &:not(:last-child) {
      margin-left: 20px;
      margin-right: unset;
    }
    .sub-head-btn {
      span {
        order: -1
      }
    }
  } 

  .section-title {
    direction: rtl;
  }

}
.search-mobile{
  display: none;
  .search-bar-container-full-width {
    box-sizing: border-box;
    position: absolute;
    right: 0;
    /* Auto layout */
  
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 24px 0px 12px;
    gap: 4px;
  
    width: 368px;
    height: 42px;
    border: 1px solid #d8dce4;
    border-radius: 100px;
  }
  
  .search-bar-container-half-width {
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 24px 0px 12px;
    gap: 4px;
    width: 93%;
    height: 36px;
    border: 1px solid #d8dce4;
    border-radius: 5px;
    background: #fff;
    z-index: 9;
    font-size: 14px;
    svg{
      color: #000;
    }
}
  
  .search-bar-input {
    width: 100%;
    border: none;
    outline: none;
    color: #8e96a6;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.02em;
  }
  
  .search-bar-container-icon-only {
    margin-top: -3px;
}
}

//responsive start
@media (max-width: 767px) {
   .toggle-nav-btn{
    margin-right: 0px !important;
   }
  .logo{
    width: 90px !important;
    height: 34px;
  }
  .flag-wrap{
    .lang-control{
      .MuiSelect-select{
        padding: 0px 5px !important;
      }
    }
    .MuiSvgIcon-root{
      display: none;
    }
    
  }
 
  .search-nav{
    margin-bottom: 15px;
    input{
      font-size: 14px;
      &::placeholder{
        font-size: 14px;
      }
    }
  }
  .search-mobile{
    display: none;
  }

}

@media (max-width: 991px) {
  .search-nav{
    margin-bottom: 15px;
    margin-left: 20px;
  }
}
@media (max-width: 1023px) {
  .search-nav{
    margin-bottom: 15px;
  }
}
@media (max-width: 1200px) {
  .search-nav{
    margin-bottom: 15px;
    margin-left: 20px;
  }
}
@media (min-width: 1200px) {
.css-1xedmm4 {
margin-right: 100px;
margin-left: unset;
}
}

@media (max-width: 1279px) {
  .search-nav{
    margin-left: 20px !important;
    margin-bottom: 0 !important;
  }
}

@media (max-width: 899px) {
  .search-mobile{
    display: none;
      .search-bar-container-half-width {
        width: 95%;
        height: 40px;
        top: 51px;
    }
  }
  .right-icon-box {
    .icon-btn-wrap{
      button {
       >div{
        padding: 0px;
       }
      }
    }
  }
  .header-content {
    padding-top: 10px;
}
.page-header .MuiAppBar-root {
  padding-bottom: 10px;
}
.search-nav {
  margin-left: 0px !important;
   max-width: 100%; 
   width: 100%;
}
}

@media (min-width: 900px){
  .search-nav{
    margin-left: 20px;
    width: max-content !important;
  }
}

// checkout page header
.header-content{
  .checkout-header{
    margin-bottom: 0;
    .checkout-title{
      h3{
          font-family: Poppins;
        font-size: 27px;
        font-weight: 700;
        line-height: 53px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;

      }
    }
  }
}

@media (max-width: 767px){
  .logo-nav {
    display: flex;
    align-items: revert;
   .logo-container {
    /* min-height: 45px; */
    min-width: 86px;
}
  }
  .checkout-header .logo-nav{
    justify-content: center;
  }
  .header-content .checkout-header .checkout-title h3 {
    font-size: 18px;
    line-height: 51px;
}
}

body.ar .search-nav .search-icon-btn {
  transform: rotate(90deg);
  float: left;
}

body.ar .search-nav .right-search-icon-btn {
  float: left !important;
  border-radius: 4px 0px 0px 4px !important;
  position: absolute;
  left: 0;
}

body.ar .search-nav .css-aeiljy {
  left: 0;
  right: unset;
  transform: rotate(90deg);
  width: 36px;
  border-radius: 0px 0px 3px 3px;
  background: rgb(0, 191, 255);
} 

// .css-k8sl8o-MuiInputBase-root {
//   width: calc(90%);
// }

.after-login-menu {
  .MuiList-root {
    min-width: 200px;
  }
}

.icon-menu-dropdown {
  p {
    display: flex;
    gap: 9px;
    align-items: center;
    font-family: poppins;
    font-size: 14px;


    img {
      max-width: 18px;
      max-height: 18px;
    }
  }

  &.sign-out {
    p {
      padding-left: 27px;
    }
  }
}