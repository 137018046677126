$theme_primary_clr: #00B4D7;



$prnding_clr: #B8A709;
$completed_clr: #008000;
$gray_clr_othr: #808080;
$gray_clr: #474646;
$black: #000;
$border_clr: #EAE9E9;
$light_gray: #ddd;
$white: #ffffff;
$cancel_clr: #ff0000;
$table_data_clr: #201010;
$title_clr: #202020;
$placeholder_clr: #A09595;
$error_clr: #FD1A1A;
$data_clr: #353535;
$credit_bg: #E5E7EF;
$no_img_txt: #444343;
$no_img_txt_other: #787676;