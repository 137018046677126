// maniform css start
.customer-acc-main {
    padding: 20px;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FAF9F9;
    gap: 20px;
    margin: 20px auto;
    
    h1 {
        font-family: Poppins;
        font-size: 25px;
        font-weight: 700;
        line-height: 38px;
    }

    .main-box {
        width: 100%;

        .title {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            padding-left: 15px;
        }

        .company-upper {
            row-gap: 15px;

            label {
                font-family: poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                display: block;
                margin-bottom: 12px;
                width: 100%;
                &.vat-title-label{
                    min-height: 48px;
                }

                &::after {
                    content: ' *';
                    color: #FF0000;
                }

                &.unastrik {
                    &:after {
                        content: '';
                    }
                }
            }

            .input-field {
                width: 100%;
                
                .MuiInputBase-root {
                    border-radius:'5px'
                }

                input {
                    width: 100%;
                    font-family: poppins;
                }

            }

            .password-msg {
                font-family: Poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                color: #000000;
                margin-top: 10px;
            }

            .list-items {
                li {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0em;
                    text-align: left;
                }

            }

        }

        .forgotPwdText {
            cursor: pointer;
            color: #10CDF2;
        }

    }

    .create-btn {
        font-family: Poppins;
        font-size: 21px;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
        background-color: #10CDF2;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        width: 100%;
        text-transform: capitalize;

        &:hover {
            background-color: #10CDF2;
        }

    }
}


@media only screen and (max-width: 1366px) {
    .customer-acc-main {
        width: 75%;
    }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .customer-acc-main h1 {
        font-size: 19px;
        line-height: 23px;
    }

    .company-upper>.MuiGrid-item {
        // max-width: 50%;
    }

    .customer-acc-main .main-box .company-upper .input-field input {
        padding: 12px 14px;
        font-size: 14px;
    }

    .customer-acc-main .main-box .company-upper label {
        font-size: 15px;
        // line-height: 19px;
    }
    .customer-acc-main .main-box .company-upper label.vat-title-label{
        // line-height: normal;
    }
    .customer-acc-main .main-box .company-upper .password-msg {
        font-size: 14px;
        line-height: 24px;
    }
}

@media only screen and (max-width: 767px) {
    .customer-acc-main h1 {
        font-size: 16px;
        line-height: 20px;
    }

    .customer-acc-main {
        width: 94%;
        padding: 20px 0px;
        gap: 10px;
    }

    .customer-acc-main .main-box .title {
        font-size: 14px;
        line-height: 21px;
        padding-left: 0;
    }

    .customer-acc-main .main-box .company-upper {
        padding: 30px 20px;
        gap: 10px;
    }

    .customer-acc-main .main-box .company-upper label {
        font-size: 14px;
        line-height: 19px;
    }

    .customer-acc-main .main-box .company-upper .input-field input {
        padding: 12px 14px;
        font-size: 14px;
    }

    .customer-acc-main .main-box .company-upper h6 {
        font-size: 14px;
        line-height: 22px;
    }

    .customer-acc-main .create-btn {
        font-size: 15px;
        line-height: 32px;
    }
}
