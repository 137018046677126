.topsell-machine-grid {
    .tm-inner-block{
        // border:1px solid red;
        padding:20px;
    }
    .MuiGrid2-root {
        .ts-card {
            padding: 20px;
            // border: 1px solid red;
            .MuiCardContent-root{
                .MuiTypography-root{
                    color: #3D3D3D;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }

        &:nth-child(1) {
            .ts-card {
                background-color: #E1FAFF;
            }
        }

        &:nth-child(2) {
            .ts-card {
                background-color: #FFEFDB;
            }
        }

        &:nth-child(3) {
            .ts-card {
                background-color: #FFD9E7;
            }
        }

        &:nth-child(4) {
            .ts-card {
                background-color: #E8FFE8;
            }
        }

        &:nth-child(5) {
            .ts-card {
                background-color: #F6F2F2;
            }
        }

        &:nth-child(6) {
            .ts-card {
                background-color: #D6E4FF;
            }
        }
    }

}
.filter-drop{
    border:1px solid rgb(195, 195, 195);
}
.save-btn {
    text-transform: none !important;
}
.filter-chip.active{
        background-color: #10CDF2;
}

.offer_banner{
    position: relative;
    text-align: center;
    font-family: Poppins, sans-serif;
    .banner_content{
        width: 100%;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50% , -50%);
        h1{
            background: linear-gradient(90deg, #FBC725 0%, #40E6F9 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 66px;
            font-weight: 700;
            line-height: 66px;
            text-transform: uppercase;
            display: block;
            margin: 0 0 15px;
        }
        p{
            font-size: 25px;
            font-weight: 300;
            line-height: 33px;
            color: #ffffff;
            margin: 0 0 24px;
        }
        .offer_btn{
            border: 1px solid #FFDD32; 
            background-color: rgba(236, 201, 54, 0.3);
            color: #ffffff;
            text-transform: uppercase;
            font-size: 25px;
            font-weight: 600;
            padding: 0 10px;
        }
    }
}

.main-content{
    .coffee-grinders-container {
        background-color: #ffffff; 
        padding: 20px;
        border:1px solid #e1e1e1;
        border-radius: 5px;
        margin:20px;
      
        h2 {
          color: #1e2a3a; 
          margin-bottom: 10px;
          border-bottom: 3px solid #008080; 
          display: inline-block;
        }
      
        p {
          color: #444; 
          line-height: 1.6;
          margin-bottom: 10px;
          font-size: 15px;
        }
      
        strong {
          font-weight: bold;
        }
      
        a {
          color: #008080; // Teal for links
          text-decoration: none;
          font-weight: bold;
          &:hover {
            text-decoration: underline;
          }
        }
      
        .coffee-grinder-links {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
      
          a {
            font-weight: 600;
            text-transform: uppercase;
            font-size:15px;
          }
        }
      }
}

@media (max-width: 1439px) {
    .offer_banner .banner_content h1{
        font-size: 50px;
        line-height: 50px;
    }
    .offer_banner .banner_content p{
        font-size: 22px;
        line-height: 25px;
        margin: 0 0 20px;
    }
    .offer_banner .banner_content .offer_btn{
        font-size: 22px;
        padding: 3px 10px;
    }
}
@media (max-width: 1199px) {
    .offer_banner .banner_content h1{
        font-size: 40px;
        line-height: 40px;
        margin: 0 0 10px;
    }
    .offer_banner .banner_content p{
        font-size: 20px;
        line-height: 22px;
        margin: 0 0 15px;
    }
    .offer_banner .banner_content .offer_btn{
        font-size: 20px;
    }
}
@media (max-width: 991px) {
    .offer_banner .banner_content h1{
        font-size: 30px;
        line-height: 30px;
        margin: 0 0 5px;
    }
    .offer_banner .banner_content p{
        font-size: 18px;
        line-height: 20px;
        margin: 0 0 10px;
    }
    .offer_banner .banner_content .offer_btn{
        font-size: 16px;
    }
}
@media (max-width: 767px) {
    .offer_banner .banner_content h1{
        font-size: 24px;
        line-height: 24px;
    }
    .offer_banner .banner_content p{
        font-size: 14px;
        line-height: 14px;
    }
    .offer_banner .banner_content .offer_btn{
        font-size: 14px;
    }

    .main-content{
        .coffee-grinders-container {
          
            h2 { 
              margin-bottom: 5px;
              font-size: 17px;
            }
          
            p {
              font-size: 13.5px;
            }
          
            .coffee-grinder-links {
              display: flex;
              justify-content: space-between;
              margin-top: 20px;
          
              a {
                font-weight: 600;
                text-transform: uppercase;
                font-size:13.5px;
              }
            }
          }
    }
}
@media (max-width: 599px) {
    .offer_banner .banner_content h1 {
        margin: 0 0 8px;
    }

    .offer_banner .banner_content p{
        font-size: 13px;
        line-height: 13px;
        margin: 0 0 12px;
    }
    .offer_banner .banner_content .offer_btn{
        font-size: 11px;
    }
}
@media (max-width: 900px) {
    .topsell-machine-grid .MuiGrid2-root .ts-card {
        padding: 5px;
    }
  }

@media (max-width: 600px) {
    .topsell-machine-grid .MuiGrid2-root .ts-card {
        padding: 5px;
    }
  }

  body.ar .sortby-btn {
    float: left;
    }

body.ar .menufacturer-brand {
    float: right;
}
.diwali-sale .item-single {
    border: 0.81px solid #21C7F1;
    background-color: #F9F8F7;
}
.makeStyles-root-1 .diwali-sale .MuiCardContent-root .MuiPaper-root {
    border: 0.81px solid #21C7F1;
}
/*
.makeStyles-root-1 .diwali-sale .css-1as1y2m-MuiCardContent-root {
    background-color: #F9F8F7;
}*/