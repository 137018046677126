$thm_blue: #00B4D7;
$white: #ffffff;

.no-wishlist {
    text-align: center;
   
    img {
        margin-top:20px;
        margin-bottom: 17px;
        max-width: 321px;
        width: 80%;
        height: auto;
    }

    h4 {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        margin-bottom: 7px;
        color: #444343;
    }

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 17px;
        color: #787676;
    }

    button {
        background-color: $thm_blue;
        color: #F7F5F5;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        padding: 14px 24px;
        border: none;
        cursor: pointer;
        text-transform: uppercase;
    }
}

.add-wish-list-modal {
    .box-size {

        .Title-head {
            display: flex;
            padding: 24px 30px;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #E5E4E4;

            h2 {
                font-size: 18px;
                line-height: 27px;
                font-weight: 700;
                color: #3E3D3D;
                margin: 0px;
            }

            button {
                padding: 0px;
                justify-content: end;
                color: #5C5959;
                transition: none;
                background-color: transparent;

                &:hover {
                    background-color: transparent;
                }

                .MuiTouchRipple-root {
                    display: none;
                }
            }
        }

        .content-box {
            padding: 24px 30px;

            .MuiFormControl-root {
                margin-bottom: 18px;

                .MuiInputBase-root {
                    .MuiInputBase-input {
                        padding: 12px 30px;
                    }

                    .MuiOutlinedInput-notchedOutline {
                        border-color: #F0EFEF;
                    }
                }
            }

            .check-box-text {
                display: flex;
                gap: 14px;
                align-items: center;
                margin-bottom: 60px;

                .MuiButtonBase-root {
                    padding: 0px;
                }

                p {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    color: #969595;
                    margin: 0px;
                }
            }

            .Upate-btn {
                button {
                    background-color: #00B4D7;
                    color: #ffffff;
                    padding: 13px 30px;
                    border: 0px;
                    width: 100%;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }
        }
    }
}

@media (max-width:575px) {
    .no-wishlist {
        padding: 10px;
    }
    
    .add-wish-list-modal {
        .box-size {
            width: 100%;
            max-width: 90%;

            .Title-head {
                padding: 14px 16px;

                h2 {
                    font-size: 14px;
                    line-height: 22px;
                }
            }

            .content-box {
                padding: 14px 16px;

                .MuiFormControl-root {
                    margin-bottom: 12px;

                    .MuiInputBase-root {
                        .MuiInputBase-input {
                            padding: 10px 16px;
                            font-size: 12px;

                        }
                    }
                }

                .check-box-text {
                    gap: 10px;
                    margin-bottom: 45px;

                    p {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }

                .Upate-btn {
                    button {
                        padding: 8px 20px;
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}