$black: #000000;
$gray: #E1DFDF;
$white: #ffffff;
$bg-gray: #C6CBDB;
$thm_blue: #00B4D7;
$light_gray: #B3B0B0;
$dark_gray: #474646;
$dark_black: #201010;

.return-order-page {
  padding: 25px 5px;

  .return-order-header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 26px;

    .header {
      margin-bottom: 15px;

      .page-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        color: $dark_gray;
        margin-bottom: 10px;
      }

      .page-desc {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: $black;
        margin-bottom: 0px;
      }
    }

    .close-btn {
      button {
        margin-bottom: 0px;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }
  }


  .return-content {
    padding: 15px;

    background-color: white;

    .item-card {
      margin-bottom: 20px;

      .item-layout {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;

        @media (max-width: 767px) {
          flex-direction: column;
        }
      }

      .item-info {
        display: flex;
        align-items: center;
        gap: 8px;

        .MuiButtonBase-root {
          padding: 0px;
        }

        .item-image {
          width: 100%;
          max-width: 80px;
          height: auto;
          object-fit: cover;
        }

        .item-details {

          h6 {
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            color: black;
            margin-bottom: 6px;
          }

          .body2 {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: black;
            margin-bottom: 2px;

            span {
              font-weight: 600;
            }
          }
        }
      }

      .return-reason {
        width: 100%;
        max-width: 350px;

        .days-left {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          margin-bottom: 16px;

          span {
            font-weight: 600;
          }
        }

        .reason-label {
          margin-bottom: 5px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          font-family: poppins;
        }

        .reason-dropdown {
          background-color: #f9f9f9;
          // margin-bottom:10px;

          .MuiOutlinedInput-notchedOutline {
            border-radius: 0px;
            border-color: #E7E4E4;
          }
        }

        .detailed-reason {
          margin-bottom: 5px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          font-family: poppins;
          margin-top:10px;

          .reason-input {
            margin-top: 2px;

            .MuiOutlinedInput-notchedOutline {
              border-radius: 0px;
              border-color: #E7E4E4;
            }

            .css-7ecsei-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
              color: #A09595;
            }
          }
        }
      }
    }

    .return-summary {
      width: 100%;
      max-width: 350px;
      margin-left: auto;
      padding: 15px 0px;

      h6 {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        color: black;
        margin-bottom: 6px;
      }

      .details {
        font-size: 13px;
        font-weight: 400;
        line-height: 21px;
        margin-bottom: 2px;
      }

      .summary-details {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
        margin-bottom: 10px;

        .MuiTypography-root {
          &:last-child {
            font-weight: 600;
          }
        }
      }



      .summary-divider {
        margin: 10px 0;
      }

      .submit-summary-button {
        margin-top: 15px;
        width: 100%;
        background-color: $thm_blue;
        border-radius: 0px;
        box-shadow: none;
      }
    }

    .action-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      @media (max-width: 768px) {
        flex-direction: column;

        button {
          margin-bottom: 10px;
        }
      }

      .back-button {
        color: #333;
      }

      .submit-button {
        background-color: #4caf50;
        color: #fff;
      }
    }
  }
}

@media (max-width: 767px) {
  .return-order-page {
    .return-content {
      .item-card {
        .item-info {
          width: 100%;

          .item-details {
            width: 100%;
          }
        }

        .return-reason {
          max-width: 100%;

          .other-reason {
            input {
              padding: 15px 14px;
            }
          }
        }
      }

      .return-summary {
        max-width: 100%;
      }
    }
  }
}

@media (max-width:575px) {
  .return-order-page {

    .return-order-header {

      .header {
        .page-title {
          font-size: 13px;
          font-weight: 700;
          line-height: 15px;
          margin-bottom: 2px;
        }
      }

    }

    .return-content {
      .item-card {
        .item-info {
          flex-wrap: wrap;
          .item-details{

            h6{
              font-size:13px;
            }

            .body2{
              font-size:12px;
            }
          }
        }

        .return-reason{
          .days-left{
            font-size: 12px;
          }

          .reason-label{
            font-size: 12px;
          }

          .productOpen{
            font-size: 12px;
            
          }
        }
      }
    }
  }
}