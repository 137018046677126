$gray_clr: #474646;
$thm_blue: #00B4D7;
$white: #ffffff;
$black: #000000;
$btn-black: #3A3939;

.Title-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;

    h3 {
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        color: $gray_clr;
        margin: 0px;
    }

    button {
        background-color: $thm_blue;
        color: $white;
        padding: 13px 17px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 700;
        border: none;
    }
}

.Wishlist-main-cont {
    display: flex;
    gap: 16px;

    .Wishlist-sidebar {
        width: 100%;
        max-width: 280px;

        .wislist-name-list {
            background-color: $white;
            padding: 11px 40px 18px;

            .name-status {
                display: flex;
                gap: 16px;
                align-items: center;
                margin-bottom: 4px;

                h4 {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    color: $black;
                    margin: 0px;
                }

                .default-cls {
                    background-color: $thm_blue;
                    padding: 0px 10px;
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                    color: $black;
                    margin: 0px;
                }
            }

            .item-count {
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                color: $black;
                display: flex;
                gap: 5px;
                align-items: center;
                margin: 0px;

                svg {
                    max-width: 14px;
                    max-height: 14px;
                }
            }
        }
    }

    .Wishlist-details-list {
        width: 100%;

        .name-head {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            .name-status {
                display: flex;
                gap: 18px;
                align-items: center;

                h4 {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                    color: $black;
                    margin: 0px;
                }

                .default-cls {
                    background-color: $thm_blue;
                    padding: 0px 10px;
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                    color: $black;
                    margin: 0px;
                }
            }

            .share-more-btn {
                display: flex;
                gap: 30px;
                align-items: center;

                button {
                    color: $btn-black;
                    padding: 0px;
                    display: flex;
                    gap: 7px;

                    &:hover {
                        background-color: transparent;
                    }

                    .MuiTouchRipple-root {
                        display: none;
                    }

                    svg {
                        max-width: 14px;
                        max-height: 14px;
                    }
                }
            }
        }

        .Wishlist-card-box {
            display: flex;
            gap: 15px;
            flex-wrap: wrap;


            .wishlist-box {
                background-color: $white;
                padding: 10px;
                border-radius: 4px;
                max-width: 300px;
                width:270px;

                .img-box {
                    position: relative;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;

                    .w-l-b-best-seller {
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        background-color: $gray_clr;
                        color: $white;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 600;
                        border-radius: 100px;
                        padding: 5px 10px;
                        margin: 0px;
                    }

                    img {
                        width: 70%;
                        max-width: 250px;
                        height: 200px;
                        object-fit: contain;
                    }

                    .w-l-b-star {
                        position: absolute;
                        bottom: 15px;
                        left: 10px;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                        gap: 3px;
                        margin: 0px;

                        svg {
                            width: 16px;
                            height: 16px;
                            color: green;
                        }

                        span {
                            color: #999999;
                        }
                    }
                }

                .details-box {
                    .w-l-b-mega-deal {
                        font-size: 14px;
                        color: #b70b0b;
                        background-color: #e9b7b7;
                        padding: 2px 5px;
                        font-weight: 600;
                        border-radius: 4px;
                        margin: 0px;
                        margin-bottom: 8px;
                    }

                    .w-l-b-name {
                        font-size: 16px;
                        font-weight: 500;
                        margin: 0px;
                        margin-bottom: 4px;
                    }

                    .w-l-b-price {
                        font-size: 14px;
                        font-weight: 400;
                        margin: 0px;
                        margin-bottom: 4px;

                        span {
                            font-size: 18px;
                            font-weight: 600;
                        }
                    }

                    .w-l-b-soldout {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        font-weight: 400;
                        gap: 4px;
                        margin: 0px;
                        margin-bottom: 4px;

                        svg {
                            width: 18px;
                            height: 18px;
                            color: green;
                        }
                    }

                    .w-l-b-express {
                        display: inline-block;
                        background: yellow;
                        border-radius: 100px;
                        border-top-right-radius: 0px;
                        padding: 2px 5px;
                        color: darkblue;
                        font-size: 12px;
                        font-weight: 600;
                        margin: 0px;
                    }
                }

                .btn-grp {
                    display: flex;
                    gap: 12px;
                    margin-top: 20px;

                    Button {
                        padding: 0px;

                        &.add-to-card-btn {
                            background-color: $thm_blue;
                            color: $white;
                            flex: 1;
                            padding: 8px 12px;
                        }

                        &.view-card-btn {
                            color:$thm_blue;
                            flex: 1;
                            padding: 8px 12px;
                            border: 1px solid $thm_blue;
                        }

                        &.More-btn {
                            padding: 8px 12px;
                            border: 1px solid $thm_blue;
                        }

                        .view-card-btn {
                            color:$thm_blue;
                            flex: 1;
                            padding: 8px 12px;
                            border: 1px solid $thm_blue;
                        }

                        .MuiTouchRipple-root {
                            display: none;
                        }
                    }
                }
            }

        }
    }
}

.Share-more-menu {
    .MuiMenu-paper {
        // overflow: visible;
        margin-top: 10px;

        ul {
            padding: 0px;
            position: relative;

            // &::before {
            //     content: '';
            //     position: absolute;
            //     width: 0;
            //     height: 0;
            //     border-left: 5px solid transparent;
            //     border-right: 5px solid transparent;
            //     border-bottom: 10px solid $white;
            //     right: 25px;
            //     top: -10px;
            // }

            .share-more-header {
                display: flex;
                gap: 10px;
                align-items: center;
                margin-bottom: 12px;
                padding: 18px 24px;

                .ttile {
                    h4 {
                        font-size: 18px;
                        line-height: 27px;
                        font-weight: 700;
                        margin: 0px;
                        margin-bottom: 2px;
                        color: #3E3D3D;
                    }

                    p {
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 400;
                        margin: 0px;
                        color: #969595;
                    }
                }

                .close-btn {
                    button {
                        background-color: transparent;
                        padding: 0px;
                        justify-content: end;
                        color: #5C5959;

                        .MuiTouchRipple-root {
                            display: none;
                        }
                    }
                }
            }

            hr {
                border-color: #E5E4E4;
            }

            .share-more-profile {
                padding: 26px 24px 21px;

                .share-more-pro-det {
                    border: 1px solid #F0EFEF;
                    padding: 8px 24px;

                    .names {
                        display: flex;
                        gap: 16px;
                        margin-bottom: 4px;

                        h4 {
                            font-size: 14px;
                            line-height: 21px;
                            font-weight: 500;
                            margin: 0px;
                        }

                        .default {
                            font-size: 12px;
                            line-height: 18px;
                            font-weight: 400;
                            background-color: $thm_blue;
                            padding: 1px 10px;
                            margin: 0px;
                        }
                    }

                    .items {
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 400;
                        display: flex;
                        align-items: center;
                        gap: 2px;
                        margin: 0px;

                        svg {
                            width: 14px;
                            height: 14px;
                        }
                    }
                }
            }

            .share-more-copy {
                padding: 18px 24px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;

                button {
                    padding: 0px;
                    color: $black;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    background-color: transparent;
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;

                    .MuiTouchRipple-root {
                        display: none;
                    }

                    svg {
                        width: 24px;
                        height: 24px;
                        padding: 3px;
                        border: 1px solid $thm_blue;
                        border-radius: 100%;
                    }
                }
            }
        }
    }
}

.more-details-menu {
    .MuiMenu-paper {
        margin-top: 10px;

        ul {
            padding: 0px;
            position: relative;

            .more-share-edit-btn {
                padding: 8px 11px;

                button {
                    background-color: transparent;
                    padding: 0px;
                    color: $black;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    color: #3A3939;
                    display: flex;
                    align-items: center;
                    gap: 7px;
                    width: 100%;
                    justify-content: flex-start;

                    svg {
                        width: 18px;
                        height: 18px;
                    }

                    .MuiTouchRipple-root {
                        display: none;
                    }
                }
            }

            .more-share-enable-disable-share {
                padding: 8px 11px;
                display: flex;
                align-items: center;
                gap: 9px;

                p {
                    display: flex;
                    gap: 11px;
                    align-items: center;

                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }
}

.edit-name-modal {
    .box-size {
        .Title-head {
            display: flex;
            gap: 10px;
            justify-content: space-between;
            padding: 20px 30px;

            h2 {
                font-size: 18px;
                line-height: 27px;
                font-weight: 700;
                color: #3E3D3D;
                margin: 0px;
            }

            button {
                padding: 0px;
                justify-content: end;
                background-color: transparent;
                color: #5C5959;

                .MuiTouchRipple-root {
                    display: none;
                }
            }
        }

        .content-box {
            padding: 30px 30px;

            .MuiFormControl-root {
                input {
                    padding: 13px 30px;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;

                }

                .MuiOutlinedInput-notchedOutline {
                    border-color: #F0EFEF;
                }
            }

            .Upate-btn {
                margin-top: 100px;

                button {
                    width: 100%;
                    border: none;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 600;
                    background-color: $thm_blue;
                    color: $white;
                    padding: 12px 30px;
                }
            }
        }
    }
}

@media (max-width:1024px) {
    .Wishlist-main-cont {
        .Wishlist-sidebar {
            max-width: 250px;

            .wislist-name-list {
                padding: 11px 20px 18px;
            }
        }

        .Wishlist-details-list {
            .name-head {
                gap: 10px;

                .name-status {
                    gap: 14px;
                }

                .share-more-btn {
                    gap: 10px;
                }
            }
        }
    }
}

@media (max-width:899px) {
    .Wishlist-main-cont {
        flex-direction: column;

        .Wishlist-sidebar {
            max-width: 100%;
        }
    }
}

@media (max-width:767px) {
    .dashboard-wrapper {
        .Title-heading {
            gap: 10px;

            button {
                padding: 8px 12px;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    .Wishlist-main-cont {
        .Wishlist-details-list {
            .name-head {
                gap: 5px;

                .name-status {
                    gap: 6px;

                    h4 {
                        font-size: 14px;
                        line-height: 16px;
                    }

                    .default-cls {
                        font-size: 10px;
                        line-height: 14px;
                        padding: 0px 6px;
                    }
                }

                .share-more-btn {
                    gap: 2px;

                    button {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }

            .Wishlist-card-box {
                justify-content: center;

                .wishlist-box {
                    // padding: 8px;
                    // max-width: 160px;

                    .img-box {
                        .w-l-b-best-seller {
                            font-size: 12px;
                            line-height: 14px;
                            padding: 3px 7px;
                        }

                        .w-l-b-star {
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }

                    .details-box {
                        .w-l-b-mega-deal {
                            font-size: 10px;
                            padding: 2px 3px;
                        }

                        .w-l-b-name {
                            font-size: 14px;
                        }

                        .w-l-b-price {
                            font-size: 12px;
                        }
                    }

                    .btn-grp {
                        button {
                            &.add-to-card-btn {
                                padding: 6px 10px;
                                line-height: 14px;
                                font-size: 12px;
                            }

                            &.More-btn {
                                padding: 6px 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// @media (max-width:575px) {
//     .Share-more-menu {
//         .MuiMenu-paper {
//             ul {
//                 &::before {
//                     display: none;
//                 }
//             }
//         }
//     }
// }