.modal-header{
    display: flex;
    justify-content: space-between;

    svg {
        margin-top: 20px;
    position: relative;
    left: -20px;
    cursor: pointer;
    }
}