@import '../../../../../assets/CSS/colors.scss';
@import '../../../../../assets/CSS/Button.scss';

$black: #000000;
$gray: #E1DFDF;
$white: #ffffff;
$bg-gray: #C6CBDB;
$thm_blue: #00B4D7;
$light_gray: #B3B0B0;
$dark_gray: #474646;
$dark_black: #201010;
$thm_red: #EF1111;

.payment-box {
    .payment-title {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 21px;

        h4 {
            font-size: 18px;
            line-height: 27px;
            font-weight: 700;
            color: $dark_gray;
            margin-bottom: 2px;
        }

        p {
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            color: $black;
            margin-bottom: 0px;
        }

        button {
            margin-top: 30px;
            background-color: #00B4D7;
            color: #fff;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            padding: 14px 22px;
            border: none;
            max-height: 50px;
            width: max-content;
            cursor: pointer;
        }
    }

    .general-info-box {
        padding: 1px 0px 27px 0px;
        background-color: $white;
        margin-bottom: 18px;

        h4 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: #202020;
            margin-bottom: 10px;
            padding: 20px 18px;
        }

        hr {
            border-color: #EFEBEB;
            margin-bottom: 16px;
        }

        .input-grp {
            padding: 0px 18px;
            display: flex;
            gap: 25px;
            margin-bottom: 33px;

            .input-box {
                flex: 1;

                .MuiTextField-root {
                    width: 100%;

                    .MuiOutlinedInput-root {
                        font-size: 13px;
                        border: none;
                        padding: 4px 0;

                        .MuiOutlinedInput-notchedOutline {
                            border-radius: 1px;
                        }
                    }
                }

                p {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    // margin-bottom: 12px;
                    color: #202020;
                }

                .MuiInputBase-root {
                    max-height: 48px;
                    width: 100%;

                    input {
                        padding: 12px;
                    }

                    .MuiSelect-select {
                        padding: 12px 26px;
                    }

                    .MuiOutlinedInput-notchedOutline {
                        border-color: $border_clr;
                    }
                }
            }
        }

        .update-btn {
            padding: 0px 18px;

            button {
                max-height: 48px;
                padding: 14px 26px;
                font-size: 14px;
                line-height: 21px;
                font-weight: 700;
                border: none;
                cursor: pointer;
                width: 100%;
                max-width: 203px;

                &.dissable {
                    background-color: #E9ECEC;
                    color: $white;
                }

                &.active {
                    background-color: $thm_blue;
                    color: $white;
                }
            }
        }
    }
}

@media (max-width:1024px) {
    .payment-box {
        .general-info-box {
            .input-grp {
                gap: 8px;
            }
        }
    }
}

@media (max-width:767px) {
    .payment-box {
        .general-info-box {
            .input-grp {
                gap: 20px;
                flex-wrap: wrap;
                padding: 0px 9px;

                .input-box {
                    // flex: auto;

                    p {
                        margin-bottom: 6px;
                        font-size: 12px;
                        line-height: 18px;
                    }

                    &.dropdown {
                        width: 100%;

                        .MuiSelect-select {
                            padding: 9px 12px;
                        }
                    }
                }
            }

            .update-btn {
                button {
                    width: 100%;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }

    }
}

@media (max-width:575px) {
    .payment-box {
        .general-info-box {
            h4 {
                padding: 0px 8px;
                margin-bottom: 21px;
            }

            hr {
                display: none;
            }

            .input-grp {
                padding: 0px 8px;

                .input-box {
                    flex: auto;
                    width: 100%;
                }

                &.another-box {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }
            }

            .update-btn {
                padding: 0px 8px;

                button {
                    max-width: 110px;
                    font-size: 10px;
                    line-height: 15px;
                    padding: 10px 16px;
                }
            }
        }

    }
}