$white: #fff;
$gray_clr: #474646;
$light_sky_clr: #EDEFF6;
$gray_clr_line: #EFEBEB;

.Menu-welcome-box {
    background-color: $white;
    margin-bottom: 5px;
    padding: 31px 0px 31px 40px;

    h4 {
        font-size: 18px;
        font-weight: 21px;
        font-weight: bold;
        color: $gray_clr;
        margin: 0px;
    }

    p {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: $gray_clr;
        margin: 0px;
        word-wrap: break-word;
    }
}

.Menu-main-list {
    background-color: $white;
    padding: 12px 0px 31px 40px;

    nav {
        padding: 0px;

        .MuiButtonBase-root.MuiListItemButton-root {
            padding: 8px 5px;
            margin-bottom: 10px;
            gap: 19px;

            img {
                max-width: 20px;
                width: 100%;
            }

            .MuiListItemText-root {
                span {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;
                    color: #706E6E;
                }

            }

            &:hover {
                background-color: $light_sky_clr;

                .MuiListItemText-root {
                    span {
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 400;
                        color: #202020;
                    }
                }
            }

            &:active {
                background-color: $light_sky_clr;

                .MuiListItemText-root {
                    span {
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 400;
                        color: #202020;
                    }
                }
            }

            &.active {
                background-color: $light_sky_clr;

                .MuiListItemText-root {
                    span {
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 400;
                        color: #202020;
                    }
                }
            }

            &.signout {
                margin-top: 10px;
                margin-bottom: 0px;
            }
        }

        .MuiButtonBase-root.MuiListItemButton-root .MuiListItemText-root.selected span {
            font-weight: bold;
        }


        hr {
            border-color: $gray_clr_line;
        }
    }
}

@media (max-width:899px) {
    .Menu-welcome-box {
        padding: 31px 0px 11px 16px;
        text-align: start;

        h4 {
            font-size: 13px;
        }
    }

    .Menu-main-list {
        .MuiButtonBase-root {
            gap: 14px;

            img {
                max-width: 20px;
                width: 100%;
            }
        }
    }
}