/* Modal.css */
.modal-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}

.modal-content {
    width: 600px;
    background: #FFF;
    box-shadow: 0px 3px 24px 1px rgba(0, 0, 0, 0.07);
    padding: 37px 29px;

}

.modal-content svg {
    width: 46px;
    height: 46px;
    fill: #EA0C0C;
    margin-bottom: 14px;

}

.modal-content h2 {
    color: #000;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    margin: 0px 0px 29px;
}

.modal-content p {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.modal-content button {
    width: 100%;
    height: 64px;
    border-radius: 5px;
    background: #00B4D8;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    border: unset;
    margin-top: 100px;
}

.open {
    display: flex;
}

@media screen and (max-width: 767px) {
    .modal-content {
        width: 300px;
        padding: 25px 20px;
    }

    .modal-content h2 {
        font-size: 27px;
        margin: 0px 0px 22px;
    }

    .modal-content p {
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 10px;
    }

    .modal-content button {
        height: 52px;
    }

    .modal-content svg {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
    }

}

@media screen and (max-width: 320px) {
    .modal-content {
        width: 250px;
    }
}