* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.service-wrapper .main-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.service-wrapper .main-body-img {
    width: 100%;
}

.service-wrapper .second-img-container {
    position: relative;
}

.service-wrapper .overlay {
    position: relative;
    height: 100% !important;
    z-index: 0;
}

.service-wrapper .overlay::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.service-wrapper .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 1;
}

.service-wrapper .overlay-text h1 {
    /* width: 559px; */
    /* height: 76px; */
    top: 174px;
    left: 440px
}

.service-wrapper .overlay-text p {
    width: 593px;
    height: 42px;
    top: 261px;
    left: 423px
}

.service-wrapper .body-img-upper-text {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
}

.service-wrapper .body-img-lower-text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;

}

.service-wrapper .second-img-container .overlay-text p {
    color: white;
    font-size: 18px;
    text-align: center;
}
.service-wrapper .service-one-block{
    background: white;
    padding: 40px 0px;
}
.service-wrapper .service-1 {
    width: 100%;
    /* height: 720px; */
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.service-wrapper .Title-service1 {
    width: 100%;
    text-align: center;
    font-size: 25px;
    font-family: Poppins;
    font-weight: 700;
    text-transform: capitalize;
    word-wrap: break-word;
}

.service-wrapper .description-service1 {
    width: 778px;
    height: 63px;
    text-align: center;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    word-wrap: break-word;
    line-height: 21px;
}

.service-wrapper .grid-container {
    margin-top: 20px;
    width: 973px;
    height: 498px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.service-wrapper .grid-item {
    text-align: center;
    border: 1px solid #5CB7EB;
    padding: 15px;
}

.service-wrapper .grid-item img {
    max-width: 100%;
    height: 100px;
}

.service-wrapper .service-two-block{
    background: #F2F1EF;
    padding: 40px 0px;
}
.service-wrapper .service-2 {
    width: 100%;
    /* height: 272px; */
    background: #F2F1EF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.service-wrapper .Title-service2 {
    width: 100%;
    height: 45px;
    text-align: center;
    font-size: 25px;
    font-family: Poppins;
    font-weight: 700;
    text-transform: capitalize;
    word-wrap: break-word;
}

.service-wrapper .description-service2 {
    width: 778px;
    height: 63px;
    text-align: center;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    word-wrap: break-word;
    line-height: 21px;
}

.service-wrapper .images-group {
    width: 100%;
    /* height: 52px; */
    display: flex;
    justify-content: center;
    overflow-x: auto;
    /* flex-direction: row; */
    /* justify-content: space-evenly; */
    align-items: center;
    gap: 20px;
    /* display: none; */
}

.service-wrapper .offering-type {
    width: 100%;
    /* height: 400px; */
    /* margin: 30px 0; */
    padding: 30px 0px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

}

.service-wrapper .Title-offering {
    width: 100%;
    text-align: center;
    font-size: 25px;
    font-family: Poppins;
    font-weight: 700;
    text-transform: capitalize;
    word-wrap: break-word;
    margin-bottom: 15px;
}

.service-wrapper .description-offering {
    margin-bottom: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.service-wrapper .description-offering .description-offering-text{
    text-align: center;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 21px;
    word-wrap: break-word;
    line-height: 21px;
    max-width: 800px;
}

.service-wrapper .cards-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;

}

.service-wrapper .card {
    position: relative;
    background-color: #F2F1EF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 266px;
    height: 260px;
}

.service-wrapper .card-img {
    /* position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0; */
    height: 178px;
    text-align: center;
}

.service-wrapper .offering-name {
    text-align: center;
}

.service-wrapper .footer {
    width: 100%;
    margin: 30px 0 0 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 252px;
    gap: 20px;
    background: #F2F1EF;
}

.service-wrapper .Title-footer {
    width: 559px;
    height: 38px;
    text-align: center;
    font-size: 25px;
    font-family: Poppins;
    font-weight: 700;
    text-transform: capitalize;
    word-wrap: break-word;
    margin-bottom: 15px;
}


.service-wrapper .footer-description {
    width: 639px;
    text-align: center;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 15px;
}

.service-wrapper .btn {
    background-color: skyblue;
    width: 186px;
    height: 48px;
    border-radius: 10px;
}

.service-wrapper .btn-text {
    text-align: center;
    font-weight: 600;
    font-size: medium;
}

.service-wrapper .btn:hover {
    background-color: rgb(104, 199, 236);
}
.service-wrapper .footer-block-section{
    padding: 40px 0px;
}
.service-wrapper .footer-block-section .Footer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.service-wrapper .contact-us-btn{
    width: 160px;
}

.service-wrapper .grid-item .product-grid-img{
  cursor:pointer;  
}

@media(min-width:320px) and (max-width:425px){
    .service-wrapper.grid-container{
        width: 660px;
        height: 300px;
    }
    .service-wrapper.description-service1,
    .service-wrapper.description-service2,
    .service-wrapper.description-offering{
        height: auto;
        width: 630px;
    }
    .service-wrapper .description-service2{
        width: 100%;
        height: auto;
    }
    .service-wrapper .footer-description{
        width: 100%;
        height: auto;
        text-align: center;
    }
    .Title-footer{
        width: 100%;
        height: auto;
        text-align: center;
    }
    .service-wrapper .description-service1{
        width: 100%;
        height: auto;
        text-align: center;
    }
    .service-wrapper .grid-container{
        width:unset;
        height: auto;
        text-align: center;
    }
    .service-wrapper .Title-footer{
        width: unset;
        height: auto;
        /* margin-bottom: 30px; */
    }
    .service-wrapper .Footer{
        text-align: center;
    }
    .service-wrapper .overlay{
        height: 300px;
    }
    .service-wrapper .main-body-img{
        object-fit: none;
        height: 100%;
    }
    .service-wrapper .second-img-container{
        height: 350px;
    }
    .service-wrapper .body-img-upper-text{
        font-size: 18px;
        line-height: 27px;
    }
    .service-wrapper .second-img-container .overlay-text p{
        font-size: 12px;
        line-height: 18px;
    }
    .service-wrapper .overlay-text p{
        width: 300px;
        height: auto;
    }
    .service-wrapper .grid-item .product-grid-img img{
        width: 100%;
        object-fit: contain;
    }
    .service-wrapper .grid-item .product-grid-img{
        width: 100%;
        height: 100px;
        margin-bottom: 15px;
        
    }
    .service-wrapper .grid-item p{
        font-size: 12px;
    }
    .service-wrapper .card{
        width: 125px;
        height: 160px;
    }
    .service-wrapper .card .card-img img{
        width: 100%;
        max-height: 100px;
    }
    .service-wrapper .offering-name{
        font-size: 12px;
    }
    .service-wrapper .card-img{
        height: 100px;
    }
    .service-wrapper .images-group{
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        object-fit: cover;
        vertical-align: middle;
        justify-content: flex-start;
    }
}
@media(min-width:426px) and (max-width:767px){
    .service-wrapper.grid-container{
        width: 660px;
        height: 300px;
    }
    .service-wrapper.description-service1,
    .service-wrapper.description-service2,
    .service-wrapper.description-offering{
        height: auto;
        width: 630px;
    }
    .service-wrapper.grid-container{
        width: 660px;
        height: 300px;
    }
    .service-wrapper.description-service1,
    .service-wrapper.description-service2,
    .service-wrapper.description-offering{
        height: auto;
        width: 630px;
    }
    .service-wrapper .description-service2{
        width: 100%;
        height: auto;
    }
    .service-wrapper .footer-description{
        width: 100%;
        height: auto;
        text-align: center;
    }
    .Title-footer{
        width: 100%;
        height: auto;
        text-align: center;
    }
    .service-wrapper .description-service1{
        width: 100%;
        height: auto;
        text-align: center;
    }
    .service-wrapper .grid-container{
        width:unset;
        height: auto;
        text-align: center;
    }
    .service-wrapper .Title-footer{
        width: unset;
        height: auto;
    }
    .service-wrapper .Footer{
        text-align: center;
    }
    .service-wrapper .overlay{
        height: 300px;
    }
    .service-wrapper .main-body-img{
        object-fit: none;
        height: 100%;
    }
    .service-wrapper .second-img-container{
        height: 350px;
    }
    .service-wrapper .body-img-upper-text{
        font-size: 18px;
        line-height: 27px;
    }
    .service-wrapper .second-img-container .overlay-text p{
        font-size: 12px;
        line-height: 18px;
    }
    .service-wrapper .overlay-text p{
        width: 300px;
        height: auto;
    }
    .service-wrapper .grid-item .product-grid-img img{
        width: 100%;
        object-fit: contain;
    }
    .service-wrapper .grid-item .product-grid-img{
        width: 100%;
        height: 100px;
        margin-bottom: 15px;
    }
    .service-wrapper .grid-item p{
        font-size: 12px;
    }
    .service-wrapper .card{
        width: 230px;
        height: 240px;
    }
    .service-wrapper .offering-name{
        font-size: 14px;
    }
    .service-wrapper .images-group{
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        object-fit: cover;
        vertical-align: middle;
        justify-content: flex-start;
    }
}
@media(min-width:768px) and (max-width:991px){
    .service-wrapper .grid-container{
        width: 660px;
        /* height: 300px; */
    }
    .service-wrapper .description-service1,
    .service-wrapper .description-service2,
    .service-wrapper .description-offering{
        height: auto;
        width: 630px;
    }
    .service-wrapper .card{
        width: 315px;
        height: auto;
    }
    .service-wrapper .footer-block-section{
        padding: 40px 0px;
    }
    .service-wrapper .images-group{
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        object-fit: cover;
        vertical-align: middle;
        justify-content: flex-start;
    }
}
@media(min-width:992px) and (max-width:1023px){
    .service-wrapper .grid-container{
        width: 660px;
        height: 300px;
    }
    .service-wrapper .card{
        width: 205px;
    }
    .service-wrapper .images-group{
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        object-fit: cover;
        vertical-align: middle;
        justify-content: flex-start;
    }
}
@media(min-width:1024px) and (max-width:1199px){
    .service-wrapper .card{
        width: 215px;
    }
}
@media(min-width:1200px) {
    .service-wrapper .card{
        width: 260px;
    }
}