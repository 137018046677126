.postpay-widget{
    margin-top: 30px !important;
    .postpay-product-widget-container-flex{
        position: relative;
        border: 1px solid #10CDF2;
        border-radius: 3px !important;
        padding: 10px !important;
        background-color: #fff !important;
        .postpay-product-widget-logo{
            position: absolute;
            top: -10px;
            left: 0px;
            .cls-logo-badge-1{
                fill: #183f8a;
            }
           
                svg{
                    path{
                        fill: #10CDF2;
                    }
                }
            
        }
    }
    .postpay-payment-schedule-list .postpay-payment-summary-schedule-item:last-child{
        width: fit-content !important;
    }
}
