$black: #000000;
$gray: #E1DFDF;
$white: #ffffff;
$bg-gray: #C6CBDB;
$thm_blue: #00B4D7;
$light_gray: #B3B0B0;
$dark_gray: #474646;
$dark_black: #201010;



// .dash-content-wrap {
//     background: $bg-gray;
// }

.order-list-header {
    display: flex;
    gap: 30px;
    margin-bottom: 40px;

    .order-name {
        margin-inline-end: auto;

        h4 {
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            color: $dark_gray;
            margin-bottom: 2px;
        }

        p {
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: $black;
            margin-bottom: 0px;
            margin-top: 4px;
        }
    }

    .order-search {
        max-width: 350px;
        width: 100%;
        margin-top: 25px;

        .MuiFormControl-root {
            margin: 0px;
            width: 100%;
            background: $white;

            .MuiInputBase-input{
                font-size:16px;
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
                border-radius: 1px;
            }
        }
    }

    .order-dropdown {
        max-width: 167px;
        width: 100%;
        margin-top: 25px;

        .MuiFormControl-root {
            margin: 0px;
            width: 100%;
            background: $white;
        
            .MuiInputBase-input{
                font-size:14px;
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
                border-radius: 1px;
            }
        }
        
    }
}

.MuiMenu-list {
    .Mui-selected {
        background-color: rgba(16, 205, 242) !important;
        color: $white;
    }

    .MuiMenuItem-root {
        padding: 10px 23px;
        font-size: 14px;
    }
}

.order-list-items {
    .title {
        h4 {
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            color: $black;
            margin-bottom: 18px;
        }
    }

    .card-items {
        background-color: $white;
        border: 1px solid $gray;
        padding: 24px;
        margin-bottom:10px;

        .card-delivery-time-number{
            display:flex;
            justify-content: space-between;
            gap:10px;
            flex-wrap:wrap;

            .card-delivery-time {
                margin-bottom: 0px;
    
                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    color: $black;
                    margin-bottom: -18px;
    
                    span {
                        color: $thm_blue;
                    }
                }
            }


            .product-order-id {
                p {
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 18px;
                    margin-bottom: 0px;
                    color: $dark_black;
                    text-align: end;
                }
            }
        }

        .product-details {
            display: flex;
            gap: 20px;
            margin-bottom: 2px;
            justify-content: space-between;
            align-items: center;

            .product-img-name {
                display: flex;

                .product-image {
                    img {
                        max-width: 130px;
                        width: 100px;
                        height: auto;
                    }
                }

                .product-name {
                    h5 {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 21px;
                        color: $dark_black;
                        max-width: 291px;
                        margin-bottom: 0px;
                    }
                }
            }

            .product-btn-grp {
                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    color: $dark_black;
                    margin-bottom: 15px;
                }

                div {
                    display: flex;
                    gap: 6px;

                    button {
                        background: $thm_blue;
                        color: $black;
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 400;
                        border: none;
                        padding: 6px 22px;
                        cursor: pointer;
                    }
                }
            }

            .product-more-details {
                display: flex;
                flex-direction: column;
                align-items: end;

                button {
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    margin-bottom: 22px;
                }
            }
        }
    }
}

@media (min-width:1201px) {
    .order-list-items {
        .card-items {
            .product-details {
                .product-img-name {
                    flex: 1;
                }
            }
        }
    }
}


@media (max-width:1200px) {
    .order-list-header {
        gap: 12px;
        margin-bottom: 40px;

        .order-search {
            max-width: 270px;
        }

        .order-dropdown {
            max-width: 137px;
        }
    }

    .order-list-items {
        .card-items {
            .product-details {
                flex-wrap: wrap;
                gap:0px;

                .product-more-details {
                    align-items: end;
                }
            }
        }
    }
}

@media (min-width:768px) {
    .order-list-items {
        .card-items {
           

            .product-details {
                .product-img-name {
                    width:100%;
                    max-width: 100%;
                    margin-top:17px;
                }
                .product-more-details {
                    margin-top: 50px;
                }
            }
        }
    }
}

@media (max-width:767px) {

    .order-list-header {
        flex-wrap: wrap;
        margin-bottom: 30px;

        .order-name {
            order: 0;
            max-width: 246px;
            margin-top: -20px;
        }

        .order-search {
            order: 2;
            max-width: 100%;
            margin-top: 0px;
        }

        .order-dropdown {
            order: 1;
            max-width: 150px;
            margin-top: 0px;
        }
    }

    .order-list-items {
        .card-items {

            .card-delivery-time-number{
    
                .card-delivery-time {
        
                    p {
                        font-size: 12px;
                    }
                }
    
    
                .product-order-id {
                    p {
                        font-size: 12px;
                    }
                }
            }
            .product-details {
                gap: 10px;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 18px;

                .product-more-details {
                    width: 100%;
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;

                    button {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }

    .MuiMenu-list .MuiMenuItem-root {
        padding: 6px 16px;
    }
}

@media (max-width:575px) {
    .order-list-items {
        .card-items {
            padding: 10px;

            .product-details {
                .product-img-name {
                    .product-image {
                        img {
                            max-width: 90px;
                            margin: 22px 4px;
                        }
                    }

                    .product-name {
                        h5 {
                            font-size: 12px;
                        }
                    }
                }

                .product-btn-grp {
                    p {
                        font-size: 12px;
                    }

                    div {
                        button {
                            font-size: 12px;
                            padding: 6px 12px;
                        }
                    }
                }

                .product-more-details {
                    button {
                        svg {
                            max-width: 14px;
                        }
                    }
                }
            }
        }
    }

    .MuiMenu-list .MuiMenuItem-root {
        padding: 6px 16px;
    }
}

.deskPagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
  
    @media (max-width: 600px) {
      flex-direction: column; 
      align-items: stretch; 
    }
  
    .pagination-controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
  
      @media (max-width: 600px) {
        justify-content: space-between;
        flex-wrap: wrap;
      }
  
      .items-label {
        font-size: 13px;
  
        @media (max-width: 600px) {
          font-size: 12px; 
        }
      }
  
      .items-select {
        font-size: 13px;
        width: 100px;
  
        @media (max-width: 600px) {
          font-size: 12px; 
          width: auto; 
        }
  
        .MuiMenuItem-root {
          font-size: 13px;
  
          @media (max-width: 600px) {
            font-size: 12px; 
          }
        }
      }
    }
  
    .MuiPagination-root {
      margin-top: 10px;
      @media (max-width: 600px) {
        display: flex;
        justify-content: center;
      }
  
      .MuiPagination-ul {
        justify-content: flex-end;
  
        @media (max-width: 600px) {
          justify-content: center; 
        }
      }
    }
  }
  