$black: #000000;
$gray: #E1DFDF;
$white: #ffffff;
$bg-gray: #C6CBDB;
$thm_blue: #00B4D7;
$light_gray: #B3B0B0;
$dark_gray: #474646;
$dark_black: #201010;

.tracking-details-main-box {
    padding: 24px 19px;

    .tracking-heder {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 26px;

        .title {
            h4 {
                font-size: 18px;
                font-weight: 700;
                line-height: 27px;
                color: $dark_gray;
                margin-bottom: 2px;
            }

            p {
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                color: $black;
                margin-bottom: 0px;
            }
        }

        .close-btn {
            button {
                margin-bottom: 0px;
                background-color: transparent;
                border: none;
                cursor: pointer;
            }
        }
    }

    .tracking-body {
        display: flex;
        gap: 21px;

        .tracking-details {
            flex: 1;

            .delivered-box {
                background: $white;
                margin-bottom: 24px;
                padding: 18px 14px;
                display: flex;
                align-items: center;

                h5 {
                    font-size: 18px;
                    line-height: 27px;
                    font-weight: 400;
                    color: $dark_black;
                    margin-bottom: 0px;
                    margin-top: 0px;
                    
                    span {
                        font-weight: 700;
                        margin-right: 6px;
                    }
                }

                img {
                    margin-right: 8px;
                }
            }

            .tracking-shopping-experience {
                background-color: $white;
                padding: 18px 24px;
                margin-bottom: 24px;

                .title {
                    margin-bottom: 27px;

                    h6 {
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 700;
                        margin-bottom: 0px;
                        color: $dark_gray;
                    }

                    p {
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 400;
                        margin-bottom: 0px;
                        color: $dark_gray;
                    }
                }

                .feedbacks-box-grp {
                    display: flex;
                    gap: 17px;

                    .feedback-grp {
                        border: 1px solid #EFEAEA;
                        padding: 10px 18px;
                        text-align: center;
                        border-radius: 5px;

                        img {
                            max-width: 28px;
                            width: 100%;
                            margin-bottom: 12px;
                        }

                        p {
                            font-size: 14px;
                            line-height: 21px;
                            font-weight: 500;
                            color: #5F5E5E;
                            margin-bottom: 18px;
                        }

                        button {
                            background-color: $thm_blue;
                            color: $white;
                            border: none;
                            padding: 5px 12px;
                            max-width: 170px;
                            width: 100%;
                            font-size: 12px;
                            line-height: 18px;
                            font-weight: 600;
                            cursor: pointer;
                        }
                    }
                }
            }

            .tracking-item-summary {
                background-color: $white;
                padding: 18px 20px;

                .title {
                    h5 {
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 700;
                        color: $dark_gray;
                        margin-bottom: 18px;
                    }
                }

                .details {
                    display: flex;
                    gap: 8px;
                    border-bottom: 1px solid black;

                    p {
                        max-width: 335px;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                        color: $dark_black;
                        margin-bottom: 8px;
                    }

                    h4 {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        color: $dark_black;
                        margin-bottom: 0px;
                    }

                    .img{
                        margin-bottom:15px;
                    }
                }

                .details:last-child {
                    border-bottom: none;
                    margin-bottom: 0px;
                }
            }
        }

        .tracking-summary-details {
            width: 100%;
            max-width: 309px;

            .tracking-delivery-address {
                padding: 16px 14px 29px;
                background-color: $white;
                // margin-bottom: 20px;

                h5 {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    color: $dark_black;
                }

                p {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 17px;
                    color: $dark_black;
                }

                h6 {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 17px;
                    color: $dark_black;

                    .verified {
                        color: #1FAF38;
                        font-weight: 600;
                    }
                }
            }

            .tracking-invoice-summary {
                padding: 16px 14px 29px;
                background-color: $white;
                display: flex;
                gap: 10px;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: 10px;

                h5 {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    color: $dark_black;
                    margin:0px;
                }

                p {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 17px;
                    color: $dark_black;
                    margin:4px;
                }

                button {
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                }
            }
        }
    }
}

@media (max-width:1200px) {
    .tracking-details-main-box {
        padding: 0px 0px;

        .tracking-body {
            gap: 12px;

            .tracking-details {
                .tracking-shopping-experience {
                    .feedbacks-box-grp {
                        .feedback-grp {
                            padding: 8px;
                        }
                    }
                }
            }

            .tracking-summary-details {
                max-width: 250px;
            }
        }
    }
}

@media (max-width:1024px) {
    .tracking-details-main-box {
        .tracking-body {
            .tracking-details {
                .delivered-box {
                    h5 {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }

            .tracking-summary-details {
                max-width: 180px;
            }
        }
    }
}

@media (max-width: 991px) {
    .tracking-details-main-box {
        .tracking-body {
            .tracking-summary-details {
                display: none;
            }
        }
    }
}

@media (max-width:767px) {
    .tracking-details-main-box {
        .tracking-body {
            .tracking-details {
                .delivered-box {
                    padding: 12px 8px;
                }

                .tracking-shopping-experience {
                    padding: 12px 8px;
                }

                .tracking-item-summary {
                    padding: 12px 8px;
                }
            }
        }
    }
}

@media (max-width:575px) {
    .tracking-details-main-box {
        .tracking-body {
            .tracking-details {
                .tracking-shopping-experience {
                    .feedbacks-box-grp {
                        flex-wrap: wrap;
                        justify-content: center;
                        gap: 8px;

                        .feedback-grp {
                            max-width: 162px;
                            padding: 10px;

                            img {
                                max-width: 20px;
                            }

                            p {
                                font-size: 10px;
                                line-height: 12px;
                                margin-bottom: 14px;
                            }

                            button {
                                font-size: 10px;
                                line-height: 12px;
                                max-width: 128px;
                            }
                        }
                    }
                }

                .tracking-item-summary {
                    .details {
                        p {
                            font-size: 12px;
                            line-height: 16px;
                        }

                        h4 {
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
}